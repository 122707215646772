import React from 'react';
import styled from 'styled-components';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Flex } from '@glean/component-library/build/serverSide';

import score from '../../images/graphic-score.svg';
import { useTheme } from '../../styles';
import { getAppHost } from '../../urlHelper';
import { Box } from '../box';
import { Button } from '../button';
import { Card } from '../card';
import { Caption, Headline, SecondaryHeadline } from '../typography';
import { Video } from '../video';

const StyledContent = styled.main`
    width: 100%;
    overflow-x: hidden;
`;

const ScoreContainer = styled.div`
    display: flex;
    justify-content: center;
`;

const Score = styled.img`
    position: relative;
    top: -175px;
    margin-bottom: -145px;

    @media (min-width: ${(props) => props.theme.screens.sm}px) {
        top: -215px;
        margin-bottom: -185px;
    }

    @media (min-width: ${(props) => props.theme.screens.md}px) {
        position: absolute;
        top: 180px;
        left: -83px;
    }

    @media (min-width: 1147px) {
        position: absolute;
        top: 180px;
        left: auto;
        right: 780px;
    }
`;

const Boxes = styled.div`
    margin: auto;
    max-width: ${(props) => props.theme.screens.lg}px;
    overflow-y: hidden;

    .iframe-container {
        position: relative;
        width: 100%;
        padding-bottom: 56.25%;
        height: 0;
    }
    .iframe-container iframe {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
    }
`;

const RawBody = (props: any) => (
    <div className={props.className}>
        <div>{props.children}</div>
    </div>
);

const Body = styled(RawBody)`
    margin: 0;
    padding: 0;

    @media (min-width: ${(props) => props.theme.screens.md}px) {
        max-width: 780px;
        margin-left: auto;

        & > div {
            padding-left: 80px;
            margin-left: 110px;
            border-left: 10px solid ${(props) => props.theme.colors.lightgrey};
        }
    }
`;

const Content: any = (props: any) => {
    const { lightgrey, darkblue } = useTheme().colors;
    return (
        <StyledContent className={props.className}>
            <Boxes>
                <Box threshold={0.05} mt={100}>
                    <Headline className="headers">One place</Headline>
                    <SecondaryHeadline className="headers">
                        for all your business knowledge
                    </SecondaryHeadline>
                    <Body>
                        <Caption>
                            UX research, marketing research, business analytics, qualitative or
                            quantitative. How you discovered it doesn’t matter to Glean.ly. Store
                            what you actually learned not just the raw data.
                        </Caption>
                        <Caption>
                            Simply presented in a way that real people in your organisation can
                            understand and benefit from.
                        </Caption>
                    </Body>
                </Box>
                <Box threshold={0.1} mt={280}>
                    <Card />
                    <SecondaryHeadline className="headers">DISCOVER THE POWER OF</SecondaryHeadline>
                    <Headline className="headers">Connected insights</Headline>
                    <Body>
                        <Caption>
                            Good quality insights are backed up by evidence. Glean.ly is unique in
                            allowing you to gather evidence from across your organisation.
                        </Caption>
                        <Caption>
                            Our living repository means insights are no longer stuck in a moment in
                            time. New research can support or disprove existing insights. Insights
                            can be combined to discover new ideas.
                        </Caption>
                        <Caption>
                            You can be sure you are building products and making business decisions
                            with the best quality information.
                        </Caption>
                    </Body>
                </Box>
                <Box threshold={0.1} mt={100}>
                    <ScoreContainer>
                        <Score src={score} />
                    </ScoreContainer>
                    <Headline className="headers">Move quickly with evidence</Headline>
                    <SecondaryHeadline className="headers">
                        Judge the quantity and quality of insights at a glance
                    </SecondaryHeadline>
                    <Body>
                        <Caption>
                            Our innovative confidence score allows you to judge the quantity and
                            quality of the evidence you have for an insight with just a glance.
                        </Caption>
                        <Caption>
                            Combining factors such as the type, age, and amount of evidence you can
                            move quickly with confidence.
                        </Caption>
                        <Caption>
                            When that decision is important provenance is paramount, you can deep
                            dive and understand the context of how the insight has been formed.
                        </Caption>
                    </Body>
                </Box>
                <Box threshold={0.3} mt={100}>
                    <SecondaryHeadline className="headers">
                        BUILT AROUND THE POWER OF
                    </SecondaryHeadline>
                    <Headline className="headers">Atomic UX Research</Headline>
                    <Body>
                        <Caption>
                            We have developed a process that solves the problem of storing user
                            research at scale called ‘Atomic UX Research’ which has become big news
                            in the UX industry. It’s being used successfully in companies large and
                            small around the world including The European Commission and Uber. This
                            is the powerful concept around which the Glean.ly research repository is
                            built.
                        </Caption>
                        <a
                            href="https://blog.prototypr.io/what-is-atomic-research-e5d9fbc1285c"
                            target="_blank"
                        >
                            <Button color={darkblue} bg={lightgrey}>
                                Learn More
                                <FontAwesomeIcon icon={'arrow-right'} />
                            </Button>
                        </a>
                    </Body>
                    <Video />
                </Box>
                <Box threshold={0.3} mt={280}>
                    <SecondaryHeadline className="headers">
                        Collaborative as a philosophy
                    </SecondaryHeadline>
                    <Headline className="headers">Secure as a priority</Headline>
                    <Body>
                        <Caption>
                            You can give access to anyone in your organisation to encourage fruitful
                            collaboration, but your valuable data is encrypted with bank level
                            security.
                        </Caption>
                        <Caption>
                            Set administration privileges in a flexible manner that suits your
                            company structure. Share certain knowledge with third parties with full
                            control meaning consultants or outsourced agencies can provide a better
                            value service using an evidence based approach while, at the same time,
                            growing your knowledge base.
                        </Caption>
                    </Body>
                </Box>
                <Box threshold={0.3} mt={100}>
                    <SecondaryHeadline className="headers">15 MINUTE DEMO VIDEO </SecondaryHeadline>
                    <Headline mb={40} className="headers">
                        See it in action
                    </Headline>
                    <Body>
                        <div className="iframe-container">
                            <iframe
                                width="560"
                                height="315"
                                src="https://www.youtube.com/embed/TcGPIyMl_x4"
                                title="YouTube video player"
                                frameBorder="0"
                                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                allowFullScreen
                            ></iframe>
                        </div>
                        <Flex mt={40} justifyContent="space-evenly" flexWrap="wrap">
                            <a
                                href="https://gleanly.youcanbook.me/"
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                <Button color={darkblue} bg={lightgrey}>
                                    Book an in-person demo
                                </Button>
                            </a>
                            <a
                                href={getAppHost('/signup')}
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                <Button color="#ffffff" bg={darkblue}>
                                    Try it yourself (30 days free)
                                </Button>
                            </a>
                        </Flex>
                    </Body>
                </Box>
            </Boxes>
        </StyledContent>
    );
};

export { Content };
