import { motion } from 'framer-motion';
import { Link } from 'gatsby';
import React, { useContext } from 'react';
import styled from 'styled-components';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import AppContext from '../../AppContext';
import connectionsXl from '../../images/graphic-header-1280.svg';
import connections from '../../images/graphic-header-960.svg';
import { useTheme } from '../../styles';
import { Button } from '../button';
import { MenuBar } from '../menu-bar/menu-bar';
import { Headline, Paragraph } from '../typography';

const StyledHeader = styled.header`
    width: 100%;
    background-color: ${(props) => props.theme.colors.white};
    position: relative;
`;

const ConnectionsImage = styled(motion.img)<any>`
    display: none;

    @media (min-width: ${(props) => props.theme.screens.sm}px) {
        display: block;
        position: absolute;
        width: 300px;
        transform: scale(2.2);
        top: 50px;
        left: 55%;
    }

    @media (min-width: ${(props) => props.theme.screens.lg}px) {
        display: none;
    }
`;

const ConnectionsImageXl = styled(motion.img)<any>`
    display: none;

    @media (min-width: ${(props) => props.theme.screens.lg}px) {
        display: block;
        position: absolute;
        top: 0px;
        left: 25%;
        transform: scale(1.1);
    }
`;

const HeaderContainer = styled.div`
    background-color: ${(props) => props.theme.colors.darkred};
    border-bottom-right-radius: 40px;
    border-bottom-left-radius: 40px;
    height: 460px;
    padding: 20px;
    position: relative;
    max-width: 1200px;

    @media (min-width: ${(props) => props.theme.screens.sm}px) {
        padding: 40px;
    }

    @media (min-width: ${(props) => props.theme.screens.md}px) {
        margin: auto;
    }
`;

const SubHeaderContainer = styled.div`
    margin: auto;
    max-width: ${(props) => props.theme.screens.lg}px;

    p {
        padding: 60px 20px;
        margin: auto;
        box-sizing: border-box;
    }

    @media (min-width: ${(props) => props.theme.screens.sm}px) {
        p {
            max-width: 500px;
        }
    }

    @media (min-width: ${(props) => props.theme.screens.md}px) {
        p {
            max-width: 655px;
            padding: 80px 20px;
        }
    }

    @media (min-width: ${(props) => props.theme.screens.lg}px) {
        p {
            max-width: 855px;
            padding: 80px 120px;
            margin: 0;
        }
    }
`;

const InfoContainer = styled(motion.div)`
    position: absolute;
    width: 65%;
    bottom: 60px;
    left: 40px;

    @media (min-width: ${(props) => props.theme.screens.sm}px) {
        left: 80px;
        bottom: 80px;

        & > p {
            font-size: 20px;
        }
    }
`;

const ButtonContainer = styled.div`
    display: flex;
    flex-direction: column;

    p > * {
        margin-left: 10px;
    }

    @media (min-width: ${(props) => props.theme.screens.sm}px) {
        align-items: center;
        flex-direction: row;
        left: 80px;

        p {
            margin-left: 20px;
        }
    }
`;

const OrgName = styled.b`
    text-transform: uppercase;

    @media (min-width: ${(props) => props.theme.screens.sm}px) {
        display: block;
    }
`;

const textVariants = {
    hidden: { opacity: 0 },
    visible: { opacity: 1 },
};

const graphicVariants = {
    hidden: { transform: 'scale(0.5)', opacity: 0 },
    visible: { transform: 'scale(2.2)', opacity: 1 },
};

const lgGraphicVariants = {
    hidden: { transform: 'scale(0.5)', opacity: 0 },
    visible: { transform: 'scale(1.1)', opacity: 1 },
};

const transition = {
    easy: 'easyIn',
    duration: 2,
};

const animations = {
    animate: 'visible',
    initial: 'hidden',
    transition,
};

const JoinHeader: React.FC = () => {
    const { white, darkblue } = useTheme().colors;
    const store = useContext(AppContext);

    return (
        <StyledHeader>
            <HeaderContainer>
                <ConnectionsImage
                    {...animations}
                    variants={graphicVariants}
                    src={connections}
                />
                <ConnectionsImageXl
                    {...animations}
                    variants={lgGraphicVariants}
                    src={connectionsXl}
                />
                <MenuBar user={true} />
                <InfoContainer {...animations} variants={textVariants}>
                    <Headline size={40} color={white}>
                        Welcome.
                    </Headline>
                    <Paragraph size={16} mt={20} color={white} fontFamilySans>
                        YOU’VE BEEN INVITED TO THE{' '}
                        <OrgName>{store.organisation.name}</OrgName> USER
                        RESEARCH REPOSITORY
                    </Paragraph>
                    <ButtonContainer>
                        <Link to="/add-user/info">
                            <Button color={darkblue} bg={white}>
                                CREATE ACCOUNT
                                <FontAwesomeIcon icon={'arrow-right'} />
                            </Button>
                        </Link>
                    </ButtonContainer>
                </InfoContainer>
            </HeaderContainer>
            <SubHeaderContainer>
                <Paragraph>
                    A single, searchable, secure,{' '}
                    <b>scalable user research repository</b>, allowing the whole
                    organisation to benefit from shared knowledge
                </Paragraph>
            </SubHeaderContainer>
        </StyledHeader>
    );
};

export { JoinHeader };
