import styled from "styled-components";
import video from "../../images/video-preview.png";
import Arrow from "../../images/arrow90.svg";
import Play from "../../images/graphic-play.svg";
import React from "react";
import { Paragraph } from "../typography";
import { useTheme } from "../../styles";

const VideoContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  left: calc(50% - 140px);
  bottom: -320px;
  margin-top: -300px;
  width: 280px;
  z-index: 100;

  p {
    margin-top: -30px;
    font-family: "Lato";
    font-style: italic;
  }

  @media (min-width: ${props => props.theme.screens.sm}px) {
    width: 600px;
    flex-direction: row;
    left: calc(50% - 280px);
    margin-top: -190px;
    bottom: -230px;

    p {
      margin-top: 60px;
      text-align: left;
    }
  }

  @media (min-width: ${props => props.theme.screens.lg}px) {
    position: absolute;
    bottom: -140px;
  }
`;

const StyledArrow = styled.img`
  transform: rotate(135deg);
  max-width: fit-content;
  margin-top: 30px;

  @media (min-width: ${props => props.theme.screens.sm}px) {
    margin-left: 30px;
    transform: rotate(45deg);
  }
`;

const StyledVideo = styled.div`
  height: 187px;
  width: 280px;
  border-radius: 20px;
  box-shadow: 9px 9px 19px 0 rgba(0, 0, 0, 0.2);
  background-color: #000000;
  background-image: url(${video});
  background-position: center;
  background-size: contain;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;

  img {
    height: 60px;
    width: 60px;
  }

  &:hover {
    opacity: 0.9;
  }
`;

const Video: React.FC = () => {
  const { lg } = useTheme().fontSize;
  return (
    <VideoContainer>
      <a href="https://www.youtube.com/watch?v=h9s3DrImGXA" target="_blank">
        <StyledVideo>
          <img src={Play} />
        </StyledVideo>
      </a>
      <StyledArrow src={Arrow} />
      <Paragraph center size={lg} p={25}>
        Watch our founder, Daniel Pidcock, explain <b>Atomic UX Research</b> in
        this 20 minute talk.
      </Paragraph>
    </VideoContainer>
  );
};

export { Video };
