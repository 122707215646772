import { motion } from 'framer-motion';
import React from 'react';
import styled from 'styled-components';

import connectionsXl from '../../images/graphic-header-1280.svg';
import connections from '../../images/graphic-header-960.svg';
import { useTheme } from '../../styles';
import { MenuBar } from '../menu-bar/menu-bar';
import { Paragraph, Title } from '../typography';

const StyledHeader = styled.header`
    width: 100%;
    background-color: ${(props) => props.theme.colors.white};
    text-align: center;
    position: relative;
`;

const ConnectionsImageSm = styled(motion.img)<any>`
    position: absolute;
    top: 70px;
    left: 40%;
    width: 300px;

    @media (min-width: ${(props) => props.theme.screens.sm}px) {
        display: none;
    }
`;

const ConnectionsImage = styled(motion.img)<any>`
    display: none;

    @media (min-width: ${(props) => props.theme.screens.sm}px) {
        display: block;
        position: absolute;
        width: 300px;
        transform: scale(2.2);
        top: 50px;
        left: 55%;
    }

    @media (min-width: ${(props) => props.theme.screens.lg}px) {
        display: none;
    }
`;

const ConnectionsImageXl = styled(motion.img)<any>`
    display: none;

    @media (min-width: ${(props) => props.theme.screens.lg}px) {
        display: block;
        position: absolute;
        top: 0px;
        left: 25%;
        transform: scale(1.1);
    }
`;

const HeaderContainer = styled.div`
    background-color: ${(props) => props.theme.colors.darkred};
    border-bottom-right-radius: 40px;
    border-bottom-left-radius: 40px;
    height: 460px;
    padding: 20px;
    position: relative;
    max-width: 1200px;

    @media (min-width: ${(props) => props.theme.screens.sm}px) {
        padding: 40px;
    }

    @media (min-width: ${(props) => props.theme.screens.md}px) {
        margin: auto;
    }
`;

const SubHeaderContainer = styled.div`
    margin: auto;
    max-width: ${(props) => props.theme.screens.lg}px;

    p {
        padding: 60px 20px;
        margin: auto;
        box-sizing: border-box;
    }

    @media (min-width: ${(props) => props.theme.screens.sm}px) {
        p {
            max-width: 500px;
        }
    }

    @media (min-width: ${(props) => props.theme.screens.md}px) {
        p {
            max-width: 655px;
            padding: 80px 20px;
        }
    }

    @media (min-width: ${(props) => props.theme.screens.lg}px) {
        p {
            max-width: 855px;
            padding: 80px 120px;
            margin: 0;
        }
    }
`;

const TitleContainer = styled.div`
    position: absolute;
    bottom: 20px;
    left: 50%;

    h1 {
        position: relative;
        left: -50%;
    }

    @media (min-width: ${(props) => props.theme.screens.sm}px) {
        left: 8%;

        h1 {
            left: 0;
        }
    }

    @media (min-width: ${(props) => props.theme.screens.md}px) {
        left: 20%;
    }

    @media (min-width: ${(props) => props.theme.screens.lg}px) {
        left: 15%;
    }
`;

const SubTitleContainer = styled.div`
    position: absolute;
    bottom: 130px;
    left: 50%;
    max-width: 136px;

    p {
        position: relative;
        left: -100%;
        text-align: left;
        font-weight: bold;
    }

    @media (min-width: ${(props) => props.theme.screens.sm}px) {
        left: 8%;

        p {
            left: 40%;
        }
    }

    @media (min-width: ${(props) => props.theme.screens.md}px) {
        left: 20%;
        max-width: 285px;

        p {
            left: -25%;
        }
    }

    @media (min-width: ${(props) => props.theme.screens.lg}px) {
        left: 8%;

        p {
            left: 5%;
        }
    }
`;

const textVariants = {
    hidden: { opacity: 0 },
    visible: { opacity: 1 },
};

const smGraphicVariants = {
    hidden: { transform: 'scale(0.5)', opacity: 0 },
    visible: { transform: 'scale(1)', opacity: 1 },
};

const graphicVariants = {
    hidden: { transform: 'scale(0.5)', opacity: 0 },
    visible: { transform: 'scale(2.2)', opacity: 1 },
};

const lgGraphicVariants = {
    hidden: { transform: 'scale(0.5)', opacity: 0 },
    visible: { transform: 'scale(1.1)', opacity: 1 },
};

const transition = {
    easy: 'easyIn',
    duration: 2,
};

const animations = {
    animate: 'visible',
    initial: 'hidden',
    transition,
};

const Header: React.FC = () => {
    const { white } = useTheme().colors;
    return (
        <StyledHeader>
            <HeaderContainer>
                <ConnectionsImageSm
                    {...animations}
                    variants={smGraphicVariants}
                    src={connections}
                />
                <ConnectionsImage {...animations} variants={graphicVariants} src={connections} />
                <ConnectionsImageXl
                    {...animations}
                    variants={lgGraphicVariants}
                    src={connectionsXl}
                />
                <MenuBar />
                <TitleContainer>
                    <Title {...animations} variants={textVariants}>
                        scales
                    </Title>
                </TitleContainer>
                <SubTitleContainer>
                    <Paragraph {...animations} variants={textVariants} mb={20} color={white}>
                        The knowledge repository that
                    </Paragraph>
                </SubTitleContainer>
            </HeaderContainer>
            <SubHeaderContainer>
                <Paragraph>
                    A single, searchable, secure, <b>scalable research repository</b>, allowing the
                    whole organisation to benefit from shared knowledge
                </Paragraph>
            </SubHeaderContainer>
        </StyledHeader>
    );
};

export { Header };
