import React from 'react';
import styled, { css } from 'styled-components';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Flex } from '@glean/component-library/build/serverSide';

import flow from '../../images/graphic-process.svg';
import score from '../../images/graphic-score-1280.svg';
import { useTheme } from '../../styles';
import { getAppHost } from '../../urlHelper';
import { AnimatedDiv } from '../animated-div';
import { Button } from '../button';
import { Card } from '../card';
import { Caption, Headline, SecondaryHeadline } from '../typography';
import { Video } from '../video';

const StyledContent = styled.main`
    width: 100%;
    margin-bottom: 80px;
`;

const Score = styled.img`
    position: absolute;
    top: -100px;
    right: -200px;
    z-index: 1;
`;

const Flow = styled.img`
    position: absolute;
    width: ${(props) => props.theme.screens.lg}px;
    top: 360px;
    left: -40px;
    z-index: 1;
`;

const Rows = styled.div`
    margin: auto;
    max-width: 1200px;
    display: flex;
    flex-direction: column;
`;

const Row = styled(AnimatedDiv)<any>`
    width: 100%;
    display: flex;
    margin-top: 120px;

    ${(props) =>
        props.mt &&
        css`
            margin-top: ${props.mt}px;
        `}
`;

type ColumnType = {
    width?: string;
};
const Column = styled.div<ColumnType>`
    width: ${(props) => props.width || '50%'};
`;

type CellType = {
    p?: number;
    pl?: number;
    pr?: number;
    pt?: number;
    pb?: number;
    m?: number | 'auto';
    align?: string;
    flex?: string;
    justify?: string;
    triangle?: boolean;
};
const Cell = styled.div<CellType>`
    background-color: ${(props) => props.color};
    border-radius: 40px;
    width: 100%;
    height: 100%;
    position: relative;

    .iframe-container {
        position: relative;
        width: 100%;
        padding-bottom: 56.25%;
        height: 0;
    }

    .iframe-container iframe {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
    }

    ${(props) =>
        props.p &&
        css`
            padding: ${props.p}px;
        `}

    ${(props) =>
        props.pr !== undefined &&
        css`
            padding-right: ${props.pr}px;
        `}

    ${(props) =>
        props.pl !== undefined &&
        css`
            padding-left: ${props.pl}px;
        `}

    ${(props) =>
        props.pt !== undefined &&
        css`
            padding-top: ${props.pt}px;
        `}

    ${(props) =>
        props.pb !== undefined &&
        css`
            padding-bottom: ${props.pb}px;
        `}

    ${(props) =>
        props.m &&
        css`
            margin: ${props.m}px;
        `}

    ${(props) =>
        props.align &&
        css`
            text-align: ${props.align};
        `}

    ${(props) =>
        props.flex &&
        css`
            display: flex;
            flex-direction: ${props.flex};

            ${props.justify &&
            css`
                justify-content: ${props.justify};
            `}
        `}

    ${(props) =>
        props.triangle &&
        css`
            border-top-left-radius: 0;

            &:after {
                content: '';
                position: absolute;
                left: -40px;
                top: 0;
                width: 0;
                height: 0;
                border-top: 40px solid white;
                border-left: 40px solid transparent;
                clear: both;
            }
        `}
`;

const ContentXl = (props: any) => {
    const { lightgrey, darkblue, white } = useTheme().colors;
    const { xxl, xxxl, subtitle } = useTheme().fontSize;
    return (
        <StyledContent className={props.className}>
            <Rows>
                <Row>
                    <Column>
                        <Cell align="center">
                            <Headline size={subtitle}>One place</Headline>
                            <SecondaryHeadline size={xxl}>
                                for all your business knowledge
                            </SecondaryHeadline>
                        </Cell>
                    </Column>
                    <Column>
                        <Cell color={white} p={80} triangle>
                            <Caption mt={0} mb={0}>
                                UX research, marketing research, business analytics, qualitative or
                                quantitative. How you discovered it doesn’t matter to Glean.ly.
                                Store what you actually learned not just the raw data.
                            </Caption>
                            <Caption mt={40} mb={0}>
                                Simply presented in a way that real people in your organisation can
                                understand and benefit from.
                            </Caption>
                        </Cell>
                    </Column>
                </Row>
                <Row>
                    <Cell color={white} flex="row">
                        <Column>
                            <Cell p={80} pt={0} pr={0} flex="column" justify="flex-end">
                                <Card />
                                <SecondaryHeadline size={xxl}>
                                    DISCOVER THE POWER OF
                                </SecondaryHeadline>
                                <Headline size={subtitle}>Connected insights</Headline>
                            </Cell>
                        </Column>
                        <Column>
                            <Cell p={80}>
                                <Caption mt={0} mb={0}>
                                    Good quality insights are backed up by evidence. Glean.ly is
                                    unique in allowing you to gather evidence from across your
                                    organisation.
                                </Caption>
                                <Caption mt={40} mb={0}>
                                    Our living repository means insights are no longer stuck in a
                                    moment in time. New research can support or disprove existing
                                    insights. Insights can be combined to discover new ideas.
                                </Caption>
                                <Caption mt={40} mb={0}>
                                    You can be sure you are building products and making business
                                    decisions with the best quality information.
                                </Caption>
                            </Cell>
                        </Column>
                    </Cell>
                </Row>
                <Row>
                    <Column width="40%">
                        <Cell flex="column" justify="center">
                            <Score src={score} />
                            <Headline size={xxxl}>Move quickly</Headline>
                            <Headline size={subtitle}>with evidence</Headline>
                            <SecondaryHeadline size={xxl}>
                                Judge the quantity and quality of insights at a glance
                            </SecondaryHeadline>
                        </Cell>
                    </Column>
                    <Column width="60%">
                        <Cell color={white} p={80} pl={200}>
                            <Caption mt={0} mb={0}>
                                Our innovative confidence score allows you to judge the quantity and
                                quality of the evidence you have for an insight with just a glance.
                            </Caption>
                            <Caption mt={40} mb={0}>
                                Combining factors such as the type, age, and amount of evidence you
                                can move quickly with confidence.
                            </Caption>
                            <Caption mt={40} mb={0}>
                                When that decision is important provenance is paramount, you can
                                deep dive and understand the context of how the insight has been
                                formed.
                            </Caption>
                        </Cell>
                    </Column>
                </Row>
                <Row>
                    <Cell color={white} flex="row" pb={180}>
                        <Column>
                            <Cell p={80} pr={0} flex="column" justify="center">
                                <SecondaryHeadline size={xxl}>
                                    BUILT AROUND THE POWER OF
                                </SecondaryHeadline>
                                <Headline size={subtitle}>Atomic UX Research</Headline>
                            </Cell>
                        </Column>
                        <Column>
                            <Cell p={80}>
                                <Caption mt={0}>
                                    We have developed a process that solves the problem of storing
                                    user research at scale called ‘Atomic UX Research’ which has
                                    become big news in the UX industry. It’s being used successfully
                                    in companies large and small around the world including The
                                    European Commission and Uber. This is the powerful concept
                                    around which the Glean.ly research repository is built.
                                </Caption>
                                <a
                                    href="https://blog.prototypr.io/what-is-atomic-research-e5d9fbc1285c"
                                    target="_blank"
                                >
                                    <Button color={darkblue} bg={lightgrey}>
                                        Learn More
                                        <FontAwesomeIcon icon={'arrow-right'} />
                                    </Button>
                                </a>
                            </Cell>
                        </Column>
                        <Flow src={flow} />
                        <Video />
                    </Cell>
                </Row>
                <Row mt={240}>
                    <Column>
                        <Cell>
                            <SecondaryHeadline size={xxl}>
                                Collaborative as a philosophy
                            </SecondaryHeadline>
                            <Headline size={subtitle}>Secure as a priority</Headline>
                        </Cell>
                    </Column>
                    <Column>
                        <Cell color={white} p={80} triangle>
                            <Caption mt={0} mb={0}>
                                You can give access to anyone in your organisation to encourage
                                fruitful collaboration, but your valuable data is encrypted with
                                bank level security.
                            </Caption>
                            <Caption mt={40} mb={0}>
                                Set administration privileges in a flexible manner that suits your
                                company structure. Share certain knowledge with third parties with
                                full control meaning consultants or outsourced agencies can provide
                                a better value service using an evidence based approach while, at
                                the same time, growing your knowledge base.
                            </Caption>
                        </Cell>
                    </Column>
                </Row>
                <Row>
                    <Cell color={white} flex="column">
                        <Cell p={80} pb={0} flex="column" justify="center">
                            <SecondaryHeadline size={xxl}>15 MINUTE DEMO VIDEO</SecondaryHeadline>
                            <Headline size={subtitle}>See it in action</Headline>
                        </Cell>
                        <Cell p={80} pt={40}>
                            <div className="iframe-container">
                                <iframe
                                    width="560"
                                    height="315"
                                    src="https://www.youtube.com/embed/TcGPIyMl_x4"
                                    title="YouTube video player"
                                    frameBorder="0"
                                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                    allowFullScreen
                                ></iframe>
                            </div>
                            <Flex mt={40} justifyContent="space-evenly" flexWrap="wrap">
                                <a
                                    href="https://gleanly.youcanbook.me/"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                >
                                    <Button color={darkblue} bg={lightgrey}>
                                        Book an in-person demo
                                    </Button>
                                </a>
                                <a
                                    href={getAppHost('/signup')}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                >
                                    <Button color="#ffffff" bg={darkblue}>
                                        Try it yourself (30 days free)
                                    </Button>
                                </a>
                            </Flex>
                        </Cell>
                    </Cell>
                </Row>
            </Rows>
        </StyledContent>
    );
};

export { ContentXl };
