import { Link } from "gatsby";
import React, { useEffect, useState } from "react";
import styled from "styled-components";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { useTheme } from "../../styles";
import { getAppHost } from "../../urlHelper";
import { Button } from "../button";
import { Paragraph } from "../typography";

const StyledCTA = styled.div`
    width: 100%;
    padding: 20px;
    background-color: ${(props) => props.theme.colors.white};
    box-shadow: 0 0 40px 0 rgba(0, 0, 0, 0.2);
    position: fixed;
    bottom: 0;
    z-index: 1000;
    text-align: center;

    button {
        margin-top: 0;
        margin-left: 20px;
    }

    .beta {
        background-color: ${(props) => props.theme.colors.darkgreen};
        color: ${(props) => props.theme.colors.white};
    }

    .display-large {
        display: none;
    }

    @media (min-width: ${(props) => props.theme.screens.sm}px) {
        .display-large {
            display: inline;
        }
    }
`;

const CTA: React.FC = () => {
    const { lightgrey, darkblue, darkgreen, white } = useTheme().colors;
    const { md } = useTheme().fontSize;

    const [url, setUrl] = useState('');

    useEffect(() => {
        setUrl(getAppHost());
    }, []);

    return (
        <StyledCTA>
            <Paragraph className="display-large" size={md}>
                Start your 30 day free trial
            </Paragraph>
            <a href={`${url}/signup`} target="_blank" rel="noopener noreferrer">
                <Button color={white} bg={darkgreen}>
                    Start a repository
                    <FontAwesomeIcon icon={'arrow-right'} />
                </Button>
            </a>
            <Link to="/package">
                <Button color={darkblue} bg={lightgrey}>
                    Pricing
                    <FontAwesomeIcon icon={'arrow-right'} />
                </Button>
            </Link>
        </StyledCTA>
    );
};

export { CTA };
