import React from "react";
import styled from "styled-components";
import { Paragraph } from "../typography";
import GraphicExperimentsLg from "../../images/graphic-experiments.svg";
import GraphicExperimentsSm from "../../images/graphic-experiments-320.svg";
import { useTheme } from "../../styles";

const StyledCard = styled.div`
  position: relative;
  top: -60px;
  left: calc(50% - 40px);

  > div {
    position: relative;
    top: 0;
    left: -50%;
    margin-bottom: -20px;
    background-color: ${props => props.theme.colors.darkblue};
    padding: 40px 20px 20px 20px;
    width: calc(100% + 80px);
    border-radius: 20px;
    color: ${props => props.theme.colors.white};
    font-size: ${props => props.theme.fontSize.lg}px;
    line-height: 1.69;
    box-shadow: 5px 5px 40px 0 rgba(57, 81, 113, 0.4);
    letter-spacing: normal;
  }

  @media (min-width: ${props => props.theme.screens.sm}px) {
    max-width: 400px;
    top: -100px;
  }

  @media (min-width: ${props => props.theme.screens.md}px) {
    max-width: 780px;
    margin-left: auto;
    left: 0;

    > div {
      max-width: 400px;
      left: 0;
    }
  }

  @media (min-width: ${props => props.theme.screens.lg}px) {
    margin-left: 0;
  }
`;

const StyledCorner = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  background-color: ${props => props.theme.colors.darkestblue};
  display: flex;
  align-items: center;
  justify-content: center;
  width: 73px;
  height: 30px;
  border-radius: 20px 0;
`;

const Tag = styled.div`
  height: 30px;
  border-radius: 15px;
  background-color: ${props => props.theme.colors.darkestblue};
  color: ${props => props.theme.colors.white};
  margin-right: 5px;
  font-family: "Lato", sans-serif;
  font-size: ${props => props.theme.fontSize.sm}px;
  padding: 5px 10px;
`;

const TagLines = styled.div`
  display: flex;
  margin-top: 10px;
`;

const Desktop = styled.div`
  display: none;

  @media (min-width: ${props => props.theme.screens.md}px) {
    display: block;
  }
`;

const StyledImageLg = styled.img`
  display: none;

  @media (min-width: ${props => props.theme.screens.sm}px) {
    display: block;
    position: absolute;
    top: -280px;
    left: -150px;
  }
`;

const StyledImageSm = styled.img`
  position: absolute;
  top: -300px;
  left: -20px;

  @media (min-width: ${props => props.theme.screens.sm}px) {
    display: none;
  }
`;

const Card: React.FC = () => {
  const { white } = useTheme().colors;
  const { sm, lg } = useTheme().fontSize;
  return (
    <StyledCard className="card">
      <div>
        <StyledImageLg src={GraphicExperimentsLg} />
        <StyledImageSm src={GraphicExperimentsSm} />
        <StyledCorner>1631</StyledCorner>
        <Paragraph mb={20} size={lg} color={white}>
          By creating connected insights we can gather evidence from across the
          whole business.
        </Paragraph>
        <Desktop>
          <Paragraph mb={30} size={sm} fontFamilySans={true} color={white}>
            This means we get a true picture of the evidence we have for making
            important business and product decisions.
          </Paragraph>
          <TagLines>
            <Tag>Insights</Tag>
            <Tag>Evidence</Tag>
            <Tag>Democratise research</Tag>
          </TagLines>
        </Desktop>
      </div>
    </StyledCard>
  );
};

export { Card };
