import React from "react";
import { Layout } from "../components/layout";
import "../bootstrap";
import { CTA } from "../components/cta";
import { Header } from "../components/header";
import { Content, ContentXl } from "../components/content";

const App = () => (
  <Layout mb={80}>
    <CTA />
    <Header />
    <Content className="small" />
    <ContentXl className="large" />
  </Layout>
);

export default App;
